<script>
import axios from "axios";
import moment from "moment";

function check_commentary_is_needed() {
  let needsCommentary = false;

  function check_if_rejected(document) {
    if (document.status === "rejected") {
      needsCommentary = true;
    }
  }

  this.application.documents.forEach(check_if_rejected);
  return needsCommentary;
}

export default {
  name: "ApplicationUpdate",
  props: {
    applicationProp: null,
  },
  data() {
    return {
      application: null,
      errorMessage: null,
    };
  },
  mounted() {
    if (!this.application) {
      this.getApplicationById();
    } else {
      this.application = this.applicationProp;
    }
  },
  methods: {
    getApplicationById() {
      axios.get("applications/" + this.$route.params.id).then((res) => {
        this.application = res.data;
      });
    },
    changeDocumentStatus(id, newStatus) {
      for (var i = 0; i < this.application.documents.length; i++) {
        if (this.application.documents[i].id == id) {
          this.application.documents[i].status = newStatus;
          return;
        }
      }
    },
    submitChanges() {
      let needsCommentary = check_commentary_is_needed.call(this);
      let commentaryIsEmpty = this.$refs.commentary.value == "";

      if (!(needsCommentary && commentaryIsEmpty)) {
        axios
          .patch(`applications/${this.application.id}/status`, {
            status: "sent",
            documents: this.application.documents,
            commentary: this.application.commentary,
          })
          .then(() => {
            this.$buefy.toast.open({
              message: "Заявление обновлено",
              type: "is-success",
              queue: false,
              position: "is-top-right",
              duration: 2500,
            });

            this.$router.push("/");
          });
      }
    },
    rejectApplication() {
      if (this.application.commentary) {
        axios
          .patch(`applications/${this.application.id}/status`, {
            status: "rejected",
            commentary: this.application.commentary,
          })
          .then(() => {
            this.$buefy.toast.open({
              message: "Заявление отклонено",
              type: "is-success",
              queue: false,
              position: "is-top-right",
              duration: 2500,
            });

            this.$router.push("/");
          });
      } else {
        this.errorMessage = "Необходимо ввести комментарий";
      }
    },

    hasPassedSixMonths() {
      // 6 месяцев - случайное число, наврятли они будут рассматривать заявку полгода
      return moment().diff(moment(this.application.sent_date), "months") >= 6;
    },
  },
};
</script>

<template>
  <div v-if="application">
    <div class="has-text-centered">
      <h1 class="is-size-4">Заявление №{{ application.id }}</h1>
      <h2 class="is-size-6">
        {{ localize(application.category, "title") }}
      </h2>
      <p>Дата подачи: {{ application.sent_date | formatDate }}</p>
      <p>Статус заявления: {{ $t(application.status) }}</p>
    </div>
    <div class="columns is-centered">
      <div class="column is-8">
        <b-table :data="application.documents">
          <b-table-column field="title" v-slot="props">
            <a
              class="is-size-6"
              :href="props.row.uploaded_file"
              target="_blank"
            >
              {{ localize(props.row.document_type, "title") }}
            </a>
          </b-table-column>
          <b-table-column field="status" v-slot="props" width="200px">
            <div
              class="is-flex is-align-items-center"
              :set="
                (statusClass = getCSSClassAndIconForDocumentStatus(
                  props.row.status
                ))
              "
            >
              <b-icon
                class="mr-4"
                :type="statusClass[1]"
                :icon="statusClass[2]"
                size="is-medium"
              >
              </b-icon>
              <span :class="statusClass[0]">
                {{ $t(props.row.status) }}
              </span>
            </div>
          </b-table-column>

          <b-table-column v-slot="props">
            <div
              v-if="!hasPassedSixMonths()"
              class="is-flex is-justify-content-end"
            >
              <b-field>
                <b-radio-button
                  size="is-small"
                  v-model="application.documents[props.index].status"
                  native-value="approved"
                  type="is-success is-light is-outlined"
                >
                  <b-icon icon="check"></b-icon>
                  <span>Принять</span>
                </b-radio-button>
                <b-radio-button
                  size="is-small"
                  v-model="application.documents[props.index].status"
                  native-value="rejected"
                  type="is-danger is-light is-outlined"
                >
                  <b-icon icon="close"></b-icon>
                  <span>Отклонить</span>
                </b-radio-button>
              </b-field>
            </div>
          </b-table-column>
        </b-table>

        <template v-if="!hasPassedSixMonths()">
          <b-field label="Комментарий">
            <b-input
              type="textarea"
              required
              validation-message="Если вы отклонили какой-либо файл, пожалуйста, напишите причину"
              v-model="application.commentary"
              ref="commentary"
            ></b-input>
          </b-field>
          <div>
            <button class="button is-success" @click="submitChanges()">
              Сохранить изменения
            </button>
          </div>
          <div class="mt-3 mb-3">
            <p class="has-text-info">
              Если вы хотите отправить заявление на доработку (чтобы студент
              заново загрузил какие-то документы), отметьте нужные документы в
              таблице выше, затем нажмите на "Сохранить изменения".
            </p>
            <p class="has-text-danger mt-3">
              Кнопка "Отклонить заявление" <b>полностью</b> отменит заявление и
              студент не сможет перезагрузить документы
            </p>
          </div>
          <div>
            <button class="button is-danger" @click="rejectApplication()">
              Отклонить заявление
            </button>
          </div>
        </template>
        <p class="has-text-danger">
          {{ errorMessage }}
        </p>
        <p
          v-if="hasPassedSixMonths()"
          class="has-text-danger has-text-centered"
        >
          С момента подачи заявления прошло 6 месяцев, редактирование заявки
          закрыто.
        </p>
      </div>
    </div>
  </div>
</template>
